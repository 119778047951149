@import '../color-shift';

// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: theme-color-shift(#282c37);
$classic-primary-color: theme-color-shift(#9baec8);
$classic-secondary-color: theme-color-shift(#d9e1e8);
$classic-highlight-color: theme-color-shift(#2b90d9);

// Differences
$success-green: lighten(#3c754d, 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: theme-color-shift(#b0c0cf);
$ui-primary-color: theme-color-shift(#9bcbed);
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: theme-color-shift(#2b90d9);

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$dark-text-color: theme-color-shift(#444b5d);
$action-button-color: theme-color-shift(#606984);

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: theme-color-shift(#444b5d);

//Newly added colors
$account-background-color: $white !default;

//Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}

$emojis-requiring-inversion: 'chains';

